import { createTheme } from '@mui/material'

// Augment the Theme interface for custom fields
declare module '@mui/material/styles' {
  interface TypeBackground {
    tag: string
    menu: string
  }
  interface TypeBackgroundOptions {
    tag?: string
    menu?: string
  }
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#5EEAD4',
      dark: '#41a394',
      light: '#7eeedc',
      contrastText: '#000'
    },
    secondary: {
      main: '#E3D026',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105'
    },
    text: {
      primary: 'rgb(226, 232, 240)',
      secondary: 'rgb(148, 163, 184)'
    },
    background: {
      menu: '#2a3d4b',
      paper: 'rgba(255, 255, 255, 0.01)',
      tag: 'rgba(94, 234, 212, 0.1)'
    },
    divider: 'rgba(203, 213, 225, 0.1)'
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    fontFamily: "'Montserrat', 'Geist Sans', -apple-system, BlinkMacSystemFont, Roboto, sans-serif"
  }
})

export default theme
