import React, { type SyntheticEvent } from 'react'
import { type SxProps, ToggleButton, ToggleButtonGroup, Box, Container, Fade } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { type TabValue } from './types'
import DevlogsTable from '../devlogs/DevlogsTable'
import ProjectsGallery from '../projects/ProjectsGallery'

interface TabsProps {
  sx?: SxProps
}

function Tabs ({ sx }: TabsProps): JSX.Element {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const selectedTab: TabValue = pathname.includes('devlogs') ? 'devlogs' : 'projects'

  const handleOnChange = (event: SyntheticEvent, value: TabValue): void => {
    if (value === null) return

    switch (value) {
      case 'devlogs':
        navigate('/devlogs')
        break
      case 'projects':
        navigate('/')
        break
      default:
        console.error('[handleOnChange] Unknown tab value')
        break
    }
  }

  return (
    <Box sx={{ ...sx }}>
      <ToggleButtonGroup
        value={selectedTab}
        exclusive
        onChange={handleOnChange}
        sx={{
          width: '100%',
          justifyContent: 'center'
        }}
        >
        <ToggleButton value='projects' color='primary'>Projects</ToggleButton>
        <ToggleButton value='devlogs' color='primary'>Devlogs</ToggleButton>
      </ToggleButtonGroup>
      <Container sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
        <Fade in={selectedTab === 'projects'}>
          <Box sx={{ gridRowStart: 1, gridColumnStart: 1 }}>
            <ProjectsGallery />
          </Box>
        </Fade>
        <Fade in={selectedTab === 'devlogs'}>
          <Box sx={{ gridRowStart: 1, gridColumnStart: 1 }}>
            <DevlogsTable />
          </Box>
        </Fade>
      </Container>
    </Box>
  )
}

export default Tabs
