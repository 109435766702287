import React, { type ChangeEvent, useState, useEffect } from 'react'
import {
  Controller,
  type Control,
  type FieldValues,
  type Path,
  type PathValue,
  type UseFormSetValue,
  type UseFormWatch
} from 'react-hook-form'
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  type SxProps
} from '@mui/material'
import FlexBox from '../common/FlexBox'
import Tag from '../common/Tag'
import { Add } from '@mui/icons-material'
import { mergeUnique } from '../common/commonUtils'

interface AddSelectProps<T extends FieldValues> {
  allStoredOptions: string[]
  control: Control<T>
  label?: string
  name: Path<T>
  multiple?: boolean
  setValue: UseFormSetValue<T>
  sx?: SxProps
  watch: UseFormWatch<T>
}

function AddSelect<T extends FieldValues> ({
  allStoredOptions,
  control,
  label = 'Select',
  name,
  multiple = false,
  setValue,
  sx,
  watch
}: AddSelectProps<T>): JSX.Element {
  const theme = useTheme()
  const currentOptions = watch(name)
  const [localOptions, setLocalOptions] = useState<string[]>([])
  const [newOption, setNewOption] = useState<string>('')
  // Combine all option lists, removing duplicates
  const allOptions = mergeUnique(allStoredOptions, localOptions)
  // Disable the "+" button if the new option is empty or already in the list
  const addDisabled = newOption === '' || allOptions.includes(newOption)

  useEffect(() => {
    // Remove any localOptions that are no longer in the currentOptions list
    const newLocalOptions = localOptions.filter((option) => currentOptions.includes(option))
    setLocalOptions(newLocalOptions)
  }, [currentOptions])

  // Update user's edits to the text input field
  const onChangeNewOption = (event: ChangeEvent<HTMLInputElement>): void => {
    setNewOption(event.target.value)
  }

  // When user clicks "+" button, add the new option to the list of options
  const onAddOption = (): void => {
    if (multiple) {
      const options = [...currentOptions, newOption] as PathValue<T, Path<T>>
      setValue(name, options, { shouldDirty: true, shouldTouch: true })
    } else {
      setValue(name, newOption as PathValue<T, Path<T>>, { shouldDirty: true, shouldTouch: true })
    }
    setLocalOptions([...localOptions, newOption])
    setNewOption('')
  }

  return (
    <FlexBox sx={{ flexGrow: 1, alignItems: 'end', ...sx }}>
      <FormControl fullWidth sx={{ mr: 2 }}>
        <InputLabel id='add-select-input-label'>{label}</InputLabel>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <Select
              labelId='add-select-input-label'
              label={label}
              onChange={onChange}
              input={<Input />}
              multiple={multiple}
              value={value}
              displayEmpty
              fullWidth
              MenuProps={{
                MenuListProps: { sx: { background: theme.palette.background.menu } }
              }}
              renderValue={(selected: string[] | string) => {
                if (!Array.isArray(selected)) {
                  selected = [selected]
                }
                return (<Box sx={{ display: 'flex' }}>
                  {selected.map((item, index) => (
                    <Tag label={item} key={`tags.${index}`} sx={{ mx: 0.5 }} />
                  ))}
                </Box>)
              }}
            >
              {allOptions.map((option, index) => (
                <MenuItem value={option} key={`options.${index}`}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <Input
        placeholder='Add new'
        value={newOption}
        onChange={onChangeNewOption}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={onAddOption} disabled={addDisabled}>
              <Add />
            </IconButton>
          </InputAdornment>
        }
      />
    </FlexBox>
  )
}

export default AddSelect
