import React, { useMemo, useState, type SyntheticEvent } from 'react'
import { Container, IconButton, Table, TableBody, TableCell, TableContainer, Toolbar } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useCreateDevlogMutation, useDeleteDevlogMutation, useFetchDevlogsQuery } from './devlogsSlice'
import { type DevlogEntry, type DevlogsQueryResponse, type DevlogTableColumn, type Order } from './types'
import Tag from '../common/Tag'
import DevlogsTableHead from './DevlogsTableHead'
import DevlogsTableRow from './DevlogsTableRow'
import { getComparator, getPublishedDate, stableSort } from './devlogUtils'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from '../navigation/navigationSlice'
import PlaceholderTable from './PlaceholderTable'
import { useNavigate } from 'react-router-dom'

function DevlogsTable (): JSX.Element {
  const navigate = useNavigate()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<DevlogTableColumn>('createdAt')
  const isAdmin = useSelector(selectIsAuthenticated)
  const [createDevlog] = useCreateDevlogMutation()
  const [deleteDevlog] = useDeleteDevlogMutation()
  const {
    data: devlogs = [],
    isLoading
    // isError,
    // error
  }: DevlogsQueryResponse = useFetchDevlogsQuery(!isAdmin) as DevlogsQueryResponse

  const sortedDevlogs = useMemo(
    () => stableSort(devlogs, getComparator(order, orderBy)),
    [devlogs, order, orderBy]
  )

  const onRequestSort = (event: React.MouseEvent<unknown>, property: DevlogTableColumn): void => {
    // Toggle the order if we're already sorting by the same property
    const isAsc = property === orderBy && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const onAddDevlog = (): void => {
    createDevlog().catch(console.error)
  }

  const onDeleteDevlog = (event: SyntheticEvent, id: string): void => {
    event.stopPropagation()
    deleteDevlog(id).catch(console.error)
  }

  const onNavigateToDevlog = (devlog: DevlogEntry): void => {
    if (isAdmin) {
      navigate(`/devlogs/edit/${devlog.id}`)
    } else {
      navigate(`/devlogs/${devlog.slug}`)
    }
  }

  return (
    <TableContainer component={Container}>
      <Table sx={{ minWidth: 400 }} aria-label="Devlog entries table">
        <DevlogsTableHead
          includeActions={isAdmin}
          onRequestSort={onRequestSort}
          order={order}
          orderBy={orderBy}
        />
        {isLoading
          ? <PlaceholderTable />
          : (<TableBody>
              {sortedDevlogs.map((devlog: DevlogEntry) => (
                <DevlogsTableRow
                  key={devlog.id}
                  onClick={() => { onNavigateToDevlog(devlog) }}
                  hover
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{devlog.title}</TableCell>
                  <TableCell>{getPublishedDate(devlog)}</TableCell>
                  <TableCell>
                    {devlog.tags.map((tag) => (
                      <Tag key={tag} label={tag} sx={{ mx: 0.25 }} />
                    ))}
                  </TableCell>
                  {isAdmin && (
                    <TableCell sx={{ textAlign: 'right' }}>
                      <IconButton onClick={(event) => { onDeleteDevlog(event, devlog.id) }}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </DevlogsTableRow>
              ))}
            </TableBody>)
        }
      </Table>
      {isAdmin && (
        <Toolbar sx={{ justifyContent: 'flex-end', '&.MuiToolbar-root': { pr: 2 } }}>
          <IconButton onClick={onAddDevlog}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Toolbar>
      )}
    </TableContainer>
  )
}

export default DevlogsTable
