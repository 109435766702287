import React from 'react'
import { useTheme } from '@mui/material'
import { type ExtraProps } from 'react-markdown'

function Link (props: JSX.IntrinsicElements['a'] & ExtraProps): JSX.Element {
  const theme = useTheme()

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      style={{ color: theme.palette.secondary.light, textDecoration: 'none' }}
      {...props}
    />
  )
}

export default Link
