/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { type User, getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  type QueryDocumentSnapshot,
  Timestamp,
  getFirestore,
  type SnapshotOptions,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  type FieldValue
} from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { firebaseConfig } from './config'
import { type DevlogDbModel, type ProjectDbModel } from './models'
import { type DevlogEntry } from '../devlogs/types'
import { type ProjectEntry } from '../projects/types'
import { type ProjectResponse, type DevlogResponse } from './types'
import store from '../app/store'
import { safeJSONParse } from '../common/commonUtils'

// ------------------------------------------------------------
export const wherePublished = where('published', '==', true)

const toTimestamp = (timestamp: number | FieldValue): Timestamp | FieldValue => {
  if (typeof timestamp === 'number') {
    return Timestamp.fromMillis(timestamp)
  }

  return timestamp
}

// Initialize Firebase and its services
// ------------------------------------------------------------
export const firebase = initializeApp(firebaseConfig)
export const firebaseAnalytics = getAnalytics(firebase)
export const firestore = getFirestore(firebase)
export const storage = getStorage(firebase)
export const auth = getAuth(firebase)

// Authentication Functionality
// ------------------------------------------------------------
const monitorAuthState = (): void => {
  onAuthStateChanged(auth, (user: User | null) => {
    if (user != null) {
      store.dispatch({ type: 'navigation/setIsAuthenticated', payload: true })
    } else {
      store.dispatch({ type: 'navigation/setIsAuthenticated', payload: false })
    }
  })
}

monitorAuthState()

// Conversion Functionality
// ------------------------------------------------------------
export const devlogConverter = {
  toFirestore (devlogEntry: DevlogEntry): DevlogDbModel {
    const { id, createdAt, lastModified, publishedOn, content, ...rest } = devlogEntry

    return {
      content: JSON.stringify(devlogEntry.content),
      createdAt: toTimestamp(devlogEntry.createdAt),
      lastModified: toTimestamp(devlogEntry.lastModified),
      publishedOn: toTimestamp(devlogEntry.publishedOn),
      ...rest
    }
  },
  fromFirestore (snapshot: QueryDocumentSnapshot, options: SnapshotOptions): DevlogEntry {
    const data = snapshot.data(options) as DevlogDbModel
    const { createdAt, lastModified, publishedOn, content, ...rest } = data
    const publishedDate = publishedOn ?? Timestamp.fromMillis(0)

    return {
      id: snapshot.id,
      createdAt: (createdAt as Timestamp).toMillis(),
      lastModified: (lastModified as Timestamp).toMillis(),
      publishedOn: (publishedDate as Timestamp).toMillis(),
      content: safeJSONParse(content),
      ...rest
    }
  }
}

export const projectConverter = {
  toFirestore (projectEntry: ProjectEntry): ProjectDbModel {
    const { id, createdAt, lastModified, ...rest } = projectEntry

    return {
      createdAt: toTimestamp(projectEntry.createdAt),
      lastModified: toTimestamp(projectEntry.lastModified),
      ...rest
    }
  },
  fromFirestore (snapshot: QueryDocumentSnapshot, options: SnapshotOptions): ProjectEntry {
    const data = snapshot.data(options) as ProjectDbModel
    const { createdAt, lastModified, ...rest } = data

    return {
      id: snapshot.id,
      createdAt: (createdAt as Timestamp).toMillis(),
      lastModified: (lastModified as Timestamp).toMillis(),
      ...rest
    }
  }
}

// ------------------------------------------------------------
export const fetchDevlogById = async (id: string): Promise<DevlogResponse> => {
  try {
    const ref = doc(firestore, 'devlogs', id).withConverter(devlogConverter)
    const snapshot = await getDoc(ref)
    const devlog = snapshot.data()

    return { devlog }
  } catch (error: any) {
    console.error('[fetchDevlogById]', error)

    return { devlog: undefined, error: error.message }
  }
}

export const fetchDevlogBySlug = async (slug: string, publishedOnly: boolean): Promise<DevlogResponse> => {
  try {
    const ref = collection(firestore, 'devlogs').withConverter(devlogConverter)
    const slugMatch = where('slug', '==', slug)
    const q = publishedOnly ? query(ref, slugMatch, wherePublished) : query(ref, slugMatch)
    const snapshot = await getDocs(q)
    const devlog = snapshot.docs[0].data()

    return { devlog }
  } catch (error: any) {
    console.error('[fetchDevlogBySlug]', error)

    return { devlog: undefined, error: error.message }
  }
}

export const fetchProjectById = async (id: string): Promise<ProjectResponse> => {
  try {
    const ref = doc(firestore, 'projects', id).withConverter(projectConverter)
    const snapshot = await getDoc(ref)
    const project = snapshot.data()

    return { project }
  } catch (error: any) {
    console.error('[fetchProjectById]', error)

    return { project: undefined, error: error.message }
  }
}
