import React from 'react'
import { Checkbox, type CheckboxProps } from '@mui/material'
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form'

interface FormCheckboxProps<T extends FieldValues> extends CheckboxProps {
  control: Control<T>
  name: Path<T>
}

/**
 * A wrapper around MUI's Checkbox component that works with react-hook-form.
 * Props extend MUI's CheckboxProps and pass them through to the Checkbox component.
 * @param name The name of the field in the form.
 * @param control The react-hook-form control object.
 */
function FormCheckbox<T extends FieldValues> ({ control, name, ...props }: FormCheckboxProps<T>): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          {...props}
          onChange={onChange}
          checked={value as boolean ?? false}
          value={value as boolean ?? false}
        />
      )}
    />
  )
}

export default FormCheckbox
