import React from 'react'
import { useTheme } from '@mui/material'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { type ExtraProps } from 'react-markdown'

function Code (props: JSX.IntrinsicElements['code'] & ExtraProps): JSX.Element {
  const theme = useTheme()
  const children = React.Children.toArray(props.children) as string[]
  const radius = theme.shape.borderRadius * 4
  const position = props.node?.position
  const inline = position?.start.line === position?.end.line

  if (inline) {
    return (
      <code
        style={{
          backgroundColor: theme.palette.action.hover,
          borderRadius: `${radius}px`,
          padding: '2px 6px'
        }}
      >
        {children}
      </code>
    )
  }

  return (
    <SyntaxHighlighter
      style={dracula}
      language={props.className?.replace('language-', '') ?? 'text'}
      customStyle={{ borderRadius: `${radius}px` }}
      codeTagProps={{ style: { borderRadius: theme.shape.borderRadius } }}
    >
      {children}
    </SyntaxHighlighter>
  )
}

export default Code
