import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { signOut } from 'firebase/auth'
import { AppBar, Box, Menu, MenuItem, Toolbar, Typography, useTheme } from '@mui/material'
import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import IconButton from '../common/IconButton'
import { githubProfileUrl, linkedInProfileUrl } from '../config'
import { selectIsAuthenticated } from './navigationSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { auth } from '../firebase/firebase'
import ImageManager from '../images/ImageManager'

function Header (): JSX.Element {
  const theme = useTheme()
  const isAdmin = useSelector(selectIsAuthenticated)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [imageManagerOpen, setImageManagerOpen] = useState(false)
  const hideTitle = pathname.includes('/devlogs/')

  const onGitHubClick = (): void => {
    window.open(githubProfileUrl, '_blank')
  }

  const onLinkedInClick = (): void => {
    window.open(linkedInProfileUrl, '_blank')
  }

  const onAdminClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const onSignOutClick = (): void => {
    signOut(auth).catch(console.error)
    onAdminMenuClose()
  }

  const onAdminMenuClose = (): void => {
    setAnchorEl(null)
  }

  const onImageEditorClick = (): void => {
    onAdminMenuClose()
    setImageManagerOpen(true)
  }

  const onHomeClick = (): void => {
    navigate('/')
  }

  const onImageManagerClose = (): void => {
    setImageManagerOpen(false)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' sx={{ background: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexBasis: '100%',
              mt: 3,
              mb: 3,
              cursor: 'pointer',
              visibility: hideTitle ? 'hidden' : 'visible'
            }}
            onClick={onHomeClick}
          >
            Shealyn Hindenlang
          </Typography>
          <Box sx={{ flexBasis: '100%', textAlign: 'right' }}>
            <IconButton onClick={onGitHubClick}>
              <GitHubIcon />
            </IconButton>
            <IconButton onClick={onLinkedInClick}>
              <LinkedInIcon />
            </IconButton>
            {isAdmin && (
              <IconButton onClick={onAdminClick}>
                <AdminPanelSettingsIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onAdminMenuClose}
        MenuListProps={{
          sx: {
            background: theme.palette.background.menu
          }
        }}
      >
        <MenuItem onClick={onImageEditorClick}>Mange Images</MenuItem>
        <MenuItem onClick={onSignOutClick}>Sign Out</MenuItem>
      </Menu>
      <ImageManager open={imageManagerOpen} onClose={onImageManagerClose} />
    </Box>
  )
}

export default Header
