import React from 'react'
import { type ExtraProps } from 'react-markdown'

function ToCLink (props: JSX.IntrinsicElements['a'] & ExtraProps): JSX.Element {
  const id = `toc-${props.href?.slice(1)}`

  return <a id={id} {...props} />
}

export default ToCLink
