/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { useEffect, useRef, useState } from 'react'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, serverTimestamp, setDoc } from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'
import { devlogConverter, firestore, storage, wherePublished } from '../firebase/firebase'
import firestoreApi from '../firebase/firestoreApi'
import { Tag } from '../common/types'
import { type DevlogUpdateRequest, type DevlogEntry, type ElementEntry } from './types'
import { createMarkdownElementList, headlineToSlug } from './devlogUtils'
import { asyncReplace } from '../common/commonUtils'
import { defaultDevlog } from './config'
import { useDeleteDevlogImagesMutation } from '../images/imagesSlice'

// Async Api
// ------------------------------------------------------------
export const devlogsApi = firestoreApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchDevlogs: builder.query<DevlogEntry[], boolean>({
      async queryFn (publishedOnly) {
        try {
          const ref = collection(firestore, 'devlogs').withConverter(devlogConverter)
          const q = publishedOnly ? query(ref, wherePublished) : ref
          const snapshot = await getDocs(q)
          const devlogs = snapshot.docs.map((doc) => doc.data())

          return { data: devlogs }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      providesTags: [Tag.DEVLOGS]
    }),
    fetchDevlogById: builder.query<DevlogEntry, string>({
      async queryFn (id) {
        try {
          const ref = doc(firestore, 'devlogs', id).withConverter(devlogConverter)
          const docSnapshot = await getDoc(ref)
          const devlogEntry = docSnapshot.data()

          return { data: devlogEntry }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      }
    }),
    updateDevlog: builder.mutation<DevlogEntry, DevlogUpdateRequest>({
      async queryFn ({ currentDevlog, newDevlog }: DevlogUpdateRequest) {
        try {
          const ref = doc(firestore, 'devlogs', currentDevlog.id).withConverter(devlogConverter)
          if (ref == null) {
            throw new Error(`[updateDevlog] Devlog entry ${currentDevlog.id} not found`)
          }
          // Update the lastModified and publishedOn timestamp if the devlog is being published
          const isPublished = !(currentDevlog.published) && newDevlog.published
          const entry = {
            ...newDevlog,
            lastModified: serverTimestamp(),
            publishedOn: isPublished ? serverTimestamp() : currentDevlog.publishedOn
          }
          await setDoc(ref, entry)
          // Now that the devlog has been updated, re-fetch it from the database
          const docSnapshot = await getDoc(ref)

          return { data: docSnapshot.data() }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.DEVLOGS]
    }),
    createDevlog: builder.mutation<DevlogEntry, void>({
      async queryFn () {
        try {
          const ref = collection(firestore, 'devlogs')
          if (ref == null) {
            throw new Error('[createDevlog] Devlogs collection not found')
          }
          const result = await addDoc(ref, defaultDevlog)
          // Now that the devlog has been created, fetch it from firestore
          const docRef = doc(firestore, 'devlogs', result.id).withConverter(devlogConverter)
          const docSnapshot = await getDoc(docRef)
          const devlogEntry = docSnapshot.data()

          return { data: devlogEntry }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.DEVLOGS]
    }),
    deleteDevlog: builder.mutation<void, string>({
      async queryFn (id) {
        try {
          const ref = doc(firestore, 'devlogs', id)
          if (ref == null) {
            throw new Error(`[deleteDevlog] Devlog entry ${id} not found`)
          }
          await deleteDoc(ref)
          // Delete the devlog's images from storage
          const [deleteDevlogImages] = useDeleteDevlogImagesMutation()
          await deleteDevlogImages(id)

          return { data: undefined }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.DEVLOGS]
    })
  })
})

export const {
  useCreateDevlogMutation,
  useDeleteDevlogMutation,
  useFetchDevlogsQuery,
  useFetchDevlogByIdQuery,
  useUpdateDevlogMutation
} = devlogsApi

// Custom Hooks
// ------------------------------------------------------------
export const useGetAllDevlogIds = (publishedOnly: boolean): string[] => {
  const { data: devlogs } = useFetchDevlogsQuery(publishedOnly)

  if (devlogs == null) return []

  const ids = devlogs.map((devlog) => devlog.id)

  return ids
}

/**
 * Inserts an h1 "Introduction" at start of content if it doesn't exist.
 * This heading is used to generate the table of contents and is hidden from the user.
 * @param content the devlog content to insert the heading into
 * @returns the content with the heading inserted
 */
const insertIntroHeading = (content: string): string => {
  const headingRegex = /^# Introduction/g
  const hasHeading = headingRegex.test(content)
  const introHeading = '# Introduction\n\n'
  const introContent = hasHeading ? content : introHeading + content

  return introContent
}

export const useProcessDevlogContent = (id: string | undefined, content: string): string => {
  const introContent = insertIntroHeading(content)
  const [processedContent, setProcessedContent] = useState<string>(introContent)
  useEffect(() => {
    const fetch = async (): Promise<string> => {
      // Find imagePath patterns in content ![alt](imagePath)
      const imageUrlRegex = /!\[(.*)\]\((.*)\)/g
      const replacedContent = await asyncReplace(introContent, imageUrlRegex, async (match, alt, imagePath) => {
        const path = `devlogs/${id}/${imagePath}`
        const storageRef = ref(storage, path)
        const url = await getDownloadURL(storageRef)
        return `![${alt}](${url})`
      })

      return replacedContent
    }
    fetch()
      .then((result) => { setProcessedContent(result) })
      .catch(console.error)
  }, [id, content])

  return processedContent
}

export const useCreateDevlogToc = (content: string): string => {
  // Regex to match markdown headings
  const headingRegex = /^(#+)\s[*_-`]*([A-Za-z\t 0-9,'"&^%@#.-_|]+)[*_-`]*/gm
  const toc = ['# Table of Contents']
  const matches = content.matchAll(headingRegex)
  for (const match of matches) {
    // Extract the heading level based on the number of '#' symbols
    const level = match[1]
    // Removing any trailing style characters characters from the title
    const title = match[2].replace(/[*_`-]+$/, '')
    const index = Number(match.index)
    // Add tabs for subheadings based on the heading level
    const tab = '&emsp;'.repeat(level.length - 1)
    // Convert the heading into a valid link
    const headingLink = headlineToSlug(title, index)
    toc.push(`[${tab}${title}](#${headingLink})`)
  }

  return toc.join('\n')
}

const formQuery = (): string => {
  const pieces: string[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'p']
  return pieces.map((piece) => `.markdown-body > ${piece}`).join(', ')
}

export const useHeadingObserver = (): string | null => {
  const observer = useRef<IntersectionObserver | null>(null)
  const topElements = useRef<ElementEntry[]>([])
  const [activeId, setActiveId] = useState<string | null>(null)

  const handleObserver = (entries: IntersectionObserverEntry[]): void => {
    const elements = topElements.current
    entries.forEach((entry) => {
      const index = elements.findIndex((e) => e?.element === entry.target)
      if (index === -1) {
        console.error(`[useHeadingObserver] Element ${entry.target.id} not found in topElements`)
        return
      }
      elements[index].isVisible = entry.isIntersecting
    })
    // Use the first visible heading as the active one
    const visible = elements.find((e) => e.isVisible)
    if (visible == null) return

    const id = visible.parent?.id ?? visible.element.id
    setActiveId(id)
  }

  useEffect(() => {
    const elements = document.querySelectorAll(formQuery())
    // Create a map of the top elements and their nearest parent header
    topElements.current = createMarkdownElementList(Array.from(elements))
    observer.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    })
    // Observe each top level element
    elements.forEach((element) => {
      observer.current?.observe(element)
    })

    return () => observer.current?.disconnect()
  }, [])

  return activeId
}
