import { useFetchDevlogsQuery } from '../devlogs/devlogsSlice'
import { useFetchProjectsQuery } from '../projects/projectsSlice'
import { mergeUnique } from './commonUtils'

const tagReducer = (tags: string[], entry: { tags: string[] }): string[] => {
  entry.tags.forEach(tag => {
    if (!tags.includes(tag)) tags.push(tag)
  })
  return tags
}

/**
 * Fetches all tags, across both devlogs and projects
 * @param publishedOnly if true, only fetches published entries
 * @returns an array of all unique tags
 */
export const useAllTags = (publishedOnly: boolean): string[] => {
  const { data: devlogs = [] } = useFetchDevlogsQuery(publishedOnly)
  const { data: projects = [] } = useFetchProjectsQuery(publishedOnly)

  const devlogTags = devlogs.reduce(tagReducer, [])
  const projectTags = projects.reduce(tagReducer, [])

  // Merge the two arrays and remove duplicates
  return mergeUnique(devlogTags, projectTags)
}
