import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  useTheme
} from '@mui/material'
import { useFetchProjectImagePathsQuery, useDeleteImageMutation, useAllDevlogImagePaths } from './imagesSlice'
import { openErrorSnackbar, openSuccessSnackbar, selectIsAuthenticated } from '../navigation/navigationSlice'
import { type ImageType } from './types'
import ImageUpload from './ImageUpload'
import ImageListItem from './ImageListItem'
import DevlogDirectory from './DevlogDirectory'
import ImageDirectory from './ImageDirectory'

interface ImageManagerProps {
  open: boolean
  onClose: () => void
}

function ImageManager ({ open, onClose }: ImageManagerProps): JSX.Element {
  const { data: projectImagePaths = [] } = useFetchProjectImagePathsQuery(false)
  const isAdmin = useSelector(selectIsAuthenticated)
  const devlogImagePaths = useAllDevlogImagePaths(!isAdmin)
  const [deleteImage] = useDeleteImageMutation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [uploadOpen, setUploadOpen] = useState(false)
  const [uploadType, setUploadType] = useState<ImageType | null>(null)
  const [devlogId, setDevlogId] = useState<string | null>(null)

  const onAddProjectImage = (): void => {
    setUploadType('projects')
    setUploadOpen(true)
    setDevlogId(null)
  }

  const onAddDevlogImage = (id: string): void => {
    setUploadType('devlogs')
    setUploadOpen(true)
    setDevlogId(id)
  }

  const onCloseUpload = (): void => {
    setUploadOpen(false)
  }

  const onDeleteImage = (imagePath: string): void => {
    deleteImage(imagePath)
      .then((res) => {
        dispatch(openSuccessSnackbar(`Image ${imagePath} deleted successfully`))
      })
      .catch((error) => {
        console.error('Error deleting image', error)
        dispatch(openErrorSnackbar(error.message))
      })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { background: theme.palette.background.menu }
      }}
    >
      <DialogTitle variant='h3'>Image Manager</DialogTitle>
      <DialogContent>
        <List sx={{ width: 'fit-content' }}>
          <ImageDirectory path='projects' name='projects/' onAdd={onAddProjectImage} />
          <List component='div' disablePadding>
            {projectImagePaths.map((path) => (
              <ImageListItem
                key={path}
                path={path}
                name={path.split('/').slice(1).join('/')}
                onDelete={onDeleteImage}
                isUsed={true}
              />
            ))}
          </List>
          <ImageDirectory path='devlogs' name='devlogs/' />
          {Object.keys(devlogImagePaths).map((devlogId) => {
            const paths = devlogImagePaths[devlogId]
            return (
              <DevlogDirectory
                key={devlogId}
                directory={devlogId}
                paths={paths}
                onDelete={onDeleteImage}
                onAdd={onAddDevlogImage}
              />
            )
          })}
        </List>
        <Dialog
          open={uploadOpen}
          onClose={onCloseUpload}
          maxWidth='lg'
          PaperProps={{
            sx: { background: theme.palette.background.menu }
          }}
        >
          <DialogTitle>Upload Image</DialogTitle>
          <DialogContent>
            <ImageUpload type={uploadType} onClose={onCloseUpload} id={devlogId} />
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  )
}

export default ImageManager
