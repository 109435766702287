import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Fab, Stack } from '@mui/material'
import { Add } from '@mui/icons-material'
import SaveIcon from '@mui/icons-material/Save'
import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { type ProjectEntry } from './types'
import { useCreateProjectMutation, useFetchProjectsQuery, useUpdateProjectsMutation } from './projectsSlice'
import ProjectCard from './ProjectCard'
import { selectIsAuthenticated } from '../navigation/navigationSlice'

const sortProjects = (projects: ProjectEntry[]): ProjectEntry[] => {
  // Sort projects by their order field
  return [...projects].sort((a, b) => a.order - b.order)
}

const hasOrderChanged = (a: ProjectEntry[], b: ProjectEntry[]): boolean => {
  if (a.length !== b.length) return true

  return a.some((project, index) => project.id !== b[index].id)
}

const hasDataChanged = (a: ProjectEntry[], b: ProjectEntry[]): boolean => {
  if (a.length !== b.length) return true

  for (let i = 0; i < a.length; i++) {
    const id = a[i].id
    if (b.find((project) => project.id === id) === undefined) {
      return true
    }
  }
  return false
}

function ProjectsGallery (): JSX.Element {
  const isAdmin = useSelector(selectIsAuthenticated)
  const [createProject] = useCreateProjectMutation()
  const [updateProjects] = useUpdateProjectsMutation()
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }))
  const {
    data = []
    // isLoading,
    // isError,
    // error
  } = useFetchProjectsQuery(!isAdmin)
  const [localData, setLocalData] = useState(data)
  const sortedData = sortProjects(data)
  const [projects, setProjects] = useState(sortedData)
  useEffect(() => {
    if (hasDataChanged(data, localData)) {
      setLocalData(data)
      setProjects(sortProjects(data))
    }
  }, [data])
  const orderHasChanged = hasOrderChanged(sortedData, projects)

  const handleAddProject = (): void => {
    createProject().catch(console.error)
  }

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event
    // If the order hasn't changed, do nothing
    if (active.id === over?.id) return
    // Move the dragged project to the new position
    const oldIndex = projects.findIndex((project) => project.id === active.id)
    const newIndex = projects.findIndex((project) => project.id === over?.id)
    const reordered = arrayMove(projects, oldIndex, newIndex)
    const newProjects = reordered.map((project, index) => ({ ...project, order: index }))
    setProjects(newProjects)
  }

  const handleSaveOrder = (): void => {
    updateProjects(projects).catch(console.error)
  }

  return (
    <>
      {isAdmin && (
        <Stack direction='row' spacing={2} sx={{ justifyContent: 'flex-end', mt: 2 }}>
          <Fab onClick={handleAddProject} size='medium' variant='extended'>
            <Add />
          </Fab>
          <Fab
            onClick={handleSaveOrder}
            disabled={!orderHasChanged}
            size='medium'
            variant='extended'
          >
            <SaveIcon />
          </Fab>
        </Stack>
      )}
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={projects.map((project) => project.id)} disabled={!isAdmin}>
          <Stack spacing={1} mt={3.5}>
            {projects.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                sx={{ margin: 'auto' }}
              />
            ))}
          </Stack>
        </SortableContext>
      </DndContext>
    </>
  )
}

export default ProjectsGallery
