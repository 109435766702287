import { serverTimestamp } from 'firebase/firestore'
import { stringifyDevlogContent } from './devlogUtils'

export const defaultDevlog = {
  slug: '',
  title: 'Title of My Devlog',
  blurb: 'A short blurb about the devlog',
  content: stringifyDevlogContent('Super interesting and insightful content goes here'),
  project: '',
  tags: [],
  published: false,
  createdAt: serverTimestamp(),
  lastModified: serverTimestamp()
}
