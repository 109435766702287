import React, { useEffect, useRef } from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { type DevlogEntry } from './types'
import Tag from '../common/Tag'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useCreateDevlogToc, useHeadingObserver, useProcessDevlogContent } from './devlogsSlice'
import { Heading as H, H1 } from '../markdown/Headings'
import Paragraph from '../markdown/Paragraph'
import Image from '../markdown/Image'
import Link from '../markdown/Link'
import ToCLink from '../markdown/ToCLink'
import { addSmoothScroll } from '../common/commonUtils'
import { getPublishedDate } from './devlogUtils'
import Code from '../markdown/Code'

interface DevlogProps {
  devlog: DevlogEntry
}

function Devlog ({ devlog }: DevlogProps): JSX.Element {
  const theme = useTheme()
  const activeId = useHeadingObserver()
  const anchorLinks = useRef<Element[]>([])
  const publishedOn = getPublishedDate(devlog)
  const content = useProcessDevlogContent(devlog.id, devlog.content)
  const tableOfContents = useCreateDevlogToc(content)

  useEffect(() => {
    // Add smooth scrolling to anchor links
    const links = document.querySelectorAll('.markdown-toc a[href^="#"]')
    links.forEach(addSmoothScroll)
    anchorLinks.current = Array.from(links)
  }, [])

  useEffect(() => {
    anchorLinks.current.forEach((entry: Element) => {
      if (entry.id === `toc-${activeId}`) {
        entry.classList.add('active')
      } else {
        entry.classList.remove('active')
      }
    })
  }, [activeId])

  return (
    <Stack mt={6} px={2}>
      <Typography variant="h3">
        {devlog.title}
      </Typography>
      <Typography variant="subtitle1" mb={6}>
        {devlog.blurb}
      </Typography>
      <Grid2 container>
        <Grid2 xs={12} sm={9} pr={6}>
          <Markdown
            components={{
              h1: H1,
              h2: H,
              h3: H,
              h4: H,
              h5: H,
              h6: H,
              img: Image,
              p: Paragraph,
              a: Link,
              code: Code
            }}
            remarkPlugins={[remarkGfm]}
            className='markdown-body'
          >
            {content}
          </Markdown>
        </Grid2>
        <Grid2 xs={12} sm={3} sx={{ height: 'fit-content', position: 'sticky', top: 0, overflowY: 'auto' }}>
          <Stack spacing={2}>
            <Typography color={theme.palette.text.secondary}>
              {publishedOn}
            </Typography>
            <Markdown
              remarkPlugins={[remarkGfm]}
              className='markdown-toc'
              components={{
                a: ToCLink
              }}
              >
              {tableOfContents}
            </Markdown>
            <Box>
              {devlog.tags.map((tag) => (
                <Tag key={tag} label={tag} sx={{ mr: 1 }} />
              ))}
            </Box>
          </Stack>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export default Devlog
