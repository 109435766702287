import React from 'react'
import { useTheme } from '@mui/material'
import { type ExtraProps } from 'react-markdown'
import { headlineToSlug } from '../devlogs/devlogUtils'
import { flattenChildren } from '../common/commonUtils'

/**
 * This is a custom component for react-markdown that renders a heading with an id.
 * Reference: https://github.com/remarkjs/react-markdown/issues/69
 */
export function Heading (props: JSX.IntrinsicElements['h1'] & ExtraProps): JSX.Element {
  const children = React.Children.toArray(props.children)
  const text = children.reduce(flattenChildren, '')
  const index = props.node?.position?.start.offset
  const slug = headlineToSlug(text, index ?? 0)
  const style = props.style ?? {}

  return React.createElement(props.node?.tagName ?? 'p', { id: slug, style }, props.children)
}

/**
 * A custom component for react-markdown that renders an h1 with a color that matches the theme.
 */
export function H1 (props: JSX.IntrinsicElements['h1'] & ExtraProps): JSX.Element {
  const theme = useTheme()

  return <Heading {...props} style={{ color: theme.palette.primary.main }} />
}
