import React from 'react'
import { Box, MenuItem, Select, Typography } from '@mui/material'
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form'
import { type ImageData } from '../images/types'
import { PROJECT_IMAGE_PREVIEW_SIZE, PROJECT_THUMBNAIL_SIZE } from './config'
import FlexBox from '../common/FlexBox'

interface ImageSelectProps<T extends FieldValues> {
  control: Control<T>
  imageOptions: ImageData[]
  name: Path<T>
}

function ImageSelect<T extends FieldValues> ({
  control,
  imageOptions,
  name
}: ImageSelectProps<T>): JSX.Element {
  const getUrlFromPath = (path: string): string => {
    const image = imageOptions.find((image) => image.path === path)
    if (image == null) return ''
    return image.url
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select
          onChange={onChange}
          value={value}
          renderValue={(selected: string) => (
            <Box sx={{ display: 'flex' }}>
              <img
                src={getUrlFromPath(selected)}
                alt={selected}
                width={PROJECT_IMAGE_PREVIEW_SIZE}
                height={PROJECT_IMAGE_PREVIEW_SIZE}
              />
            </Box>
          )}
        >
          {imageOptions.map(({ path, url }) => (
            <MenuItem key={path} value={path}>
              <FlexBox>
                <img
                  src={url}
                  alt={path}
                  width={PROJECT_THUMBNAIL_SIZE}
                  height={PROJECT_THUMBNAIL_SIZE}
                />
                <Typography variant='caption' ml={1}>{path}</Typography>
              </FlexBox>
            </MenuItem>
          ))}
        </Select>
      )}
    />
  )
}

export default ImageSelect
