import React from 'react'
import { type SxProps } from '@mui/material'
import {
  type Control,
  type UseFormWatch,
  type UseFormSetValue,
  type FieldValues,
  type Path
} from 'react-hook-form'
import { useAllTags } from '../common/commonHooks'
import AddSelect from './AddSelect'

interface TagSelectProps<T extends FieldValues> {
  control: Control<T>
  watch: UseFormWatch<T>
  setValue: UseFormSetValue<T>
  sx?: SxProps
}

function TagSelect<T extends FieldValues> ({ control, watch, setValue, sx }: TagSelectProps<T>): JSX.Element {
  const allStoredTags = useAllTags(false)
  const name = 'tags' as Path<T>

  return (
    <AddSelect
      allStoredOptions={allStoredTags}
      control={control}
      multiple
      label='Tags'
      name={name}
      setValue={setValue}
      sx={sx}
      watch={watch}
    />
  )
}

export default TagSelect
