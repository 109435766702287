/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  writeBatch
} from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'
import { firestore, projectConverter, storage, wherePublished } from '../firebase/firebase'
import { type ProjectEntry } from './types'
import firestoreApi from '../firebase/firestoreApi'
import { Tag } from '../common/types'
import { defaultProject } from './config'

// Async Api
// ------------------------------------------------------------
export const projectsApi = firestoreApi.injectEndpoints({
  endpoints: (builder) => ({
    createProject: builder.mutation<ProjectEntry, void>({
      async queryFn () {
        try {
          const ref = collection(firestore, 'projects')
          if (ref == null) {
            throw new Error('Projects collection not found')
          }
          const result = await addDoc(ref, defaultProject)
          // Now that the project has been created, fetch it from the database
          const docRef = doc(firestore, 'projects', result.id).withConverter(projectConverter)
          const docSnapshot = await getDoc(docRef)
          const projectEntry = docSnapshot.data()

          return { data: projectEntry }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.PROJECTS]
    }),
    deleteProject: builder.mutation<void, string>({
      async queryFn (projectId) {
        try {
          const ref = doc(firestore, 'projects', projectId)
          if (ref == null) {
            throw new Error('Project not found')
          }
          await deleteDoc(ref)

          return { data: undefined }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.PROJECTS]
    }),
    updateProject: builder.mutation<ProjectEntry, ProjectEntry>({
      async queryFn (projectEntry) {
        try {
          const ref = doc(firestore, 'projects', projectEntry.id).withConverter(projectConverter)
          if (ref == null) {
            throw new Error(`[updateProject] Project ${projectEntry.id} not found`)
          }
          // Update the lastModified timestamp
          const entry = { ...projectEntry, lastModified: serverTimestamp() }
          await setDoc(ref, entry)
          // Now that the project has been updated, re-fetch it from the database
          const docSnapshot = await getDoc(ref)

          return { data: docSnapshot.data() }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.PROJECTS]
    }),
    updateProjects: builder.mutation<ProjectEntry[], ProjectEntry[]>({
      async queryFn (projectEntries) {
        try {
          const ref = collection(firestore, 'projects')
          if (ref == null) {
            throw new Error('[updateProjects] Projects collection not found')
          }
          const batch = writeBatch(firestore)
          projectEntries.forEach((projectEntry) => {
            const docRef = doc(firestore, 'projects', projectEntry.id).withConverter(projectConverter)
            // Update the lastModified timestamp
            const entry = { ...projectEntry, lastModified: serverTimestamp() }
            batch.set(docRef, entry)
          })
          await batch.commit()

          return { data: projectEntries }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.PROJECTS]
    }),
    fetchProjects: builder.query<ProjectEntry[], boolean>({
      async queryFn (publishedOnly) {
        try {
          const ref = collection(firestore, 'projects').withConverter(projectConverter)
          const q = publishedOnly ? query(ref, wherePublished) : ref
          const snapshot = await getDocs(q)
          const projects = snapshot.docs.map((doc) => doc.data())

          return { data: projects }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      providesTags: [Tag.PROJECTS]
    }),
    fetchImageUrl: builder.query<string, string>({
      async queryFn (imagePath) {
        try {
          if (imagePath === '' || imagePath == null) {
            console.warn('[fetchImageUrl] imagePath is empty')
            return { data: '' }
          }

          const storageRef = ref(storage, imagePath)
          const url = await getDownloadURL(storageRef)

          return { data: url }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      providesTags: (result, error, imagePath) => [{ type: Tag.PROJECTS, id: imagePath }]
    })
  })
})

export const {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectsMutation,
  useFetchProjectsQuery,
  useFetchImageUrlQuery
} = projectsApi
