import React from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Header from './navigation/Header'
import ErrorSnackbar from './common/ErrorSnackbar'
import SuccessSnackbar from './common/SuccessSnackbar'
import Footer from './navigation/Footer'

function App (): JSX.Element {
  return (
    <Box sx={{ background: 'transparent' }}>
      <Header />
      <Outlet />
      <Footer />
      <ErrorSnackbar />
      <SuccessSnackbar />
    </Box>
  )
}

export default App
