import React, { type SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Box, Skeleton, Stack, type SxProps, Typography, useTheme, Button } from '@mui/material'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { type ProjectEntry } from './types'
import IconButton from '../common/IconButton'
import Tag from '../common/Tag'
import { useDeleteProjectMutation, useFetchImageUrlQuery } from './projectsSlice'
import { selectIsAuthenticated } from '../navigation/navigationSlice'
import { PROJECT_IMAGE_SIZE } from './config'
import { useNavigate } from 'react-router-dom'

interface ProjectCardProps {
  project: ProjectEntry
  sx?: SxProps
}

function ProjectCard ({ project, sx }: ProjectCardProps): JSX.Element {
  const { id, githubLink, siteLink, previewImage, tags, title, blurb, description } = project
  const theme = useTheme()
  const navigate = useNavigate()
  const [deleteProject] = useDeleteProjectMutation()
  const isAdmin = useSelector(selectIsAuthenticated)
  const { data: imageUrl } = useFetchImageUrlQuery(previewImage)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const usePlaceholder = imageUrl == null
  const hasSite = siteLink != null && siteLink !== ''

  const onGitHubClick = (): void => {
    window.open(githubLink, '_blank')
  }

  const onSiteClick = (): void => {
    window.open(siteLink, '_blank')
  }

  const onDeleteProject = (event: SyntheticEvent): void => {
    event.stopPropagation()
    deleteProject(id).catch(console.error)
  }

  const onEditProject = (event: SyntheticEvent): void => {
    event.stopPropagation()
    navigate(`/projects/edit/${id}`)
  }

  return (
    <Stack
      ref={setNodeRef}
      onClick={onGitHubClick}
      className='project-card'
      style={{
        transform: CSS.Transform.toString(transform),
        transition: `box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, ${transition}`
      }}
      {...attributes}
      {...listeners}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        p: 2,
        cursor: 'pointer',
        '&:hover': {
          boxShadow: theme.shadows[2],
          backgroundColor: 'rgba(255, 255, 255, 0.07)'
        },
        ...sx
      }}
    >
      <Stack direction='row' spacing={2}>
        <Stack>
          {usePlaceholder
            ? (<Skeleton variant='rounded' sx={{ height: PROJECT_IMAGE_SIZE }} />)
            : (<img
                src={imageUrl ?? ''}
                width={PROJECT_IMAGE_SIZE}
                height={PROJECT_IMAGE_SIZE}
                alt={title}
                style={{ borderRadius: theme.shape.borderRadius }}
              />)
          }
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant='h4'
            sx={{
              color: theme.palette.text.primary,
              '.project-card:hover &': { color: theme.palette.primary.main }
            }}
          >
            {title}
          </Typography>
          <Typography variant='subtitle2'>
            {blurb}
          </Typography>
          <Typography variant='subtitle2' sx={{ color: theme.palette.text.secondary }}>
            {description}
          </Typography>
          {hasSite && (
            <Box>
              <Button
                onClick={onSiteClick}
                className='project-card-site-link'
                sx={{
                  color: theme.palette.text.secondary,
                  borderRadius: theme.shape.borderRadius,
                  '&:hover': { color: theme.palette.primary.main }
                }}
                endIcon={(
                  <ArrowOutwardIcon
                    sx={{
                      position: 'relative',
                      top: 0,
                      left: 0,
                      transition: 'top 0.2s, left 0.2s, color 0.2s',
                      '.project-card-site-link:hover &': {
                        top: -2,
                        left: 2,
                        color: theme.palette.primary.main
                      }
                    }}
                  />
                )}
              >
                <Typography variant='subtitle2' sx={{ textTransform: 'none' }}>Website</Typography>
              </Button>
            </Box>
          )}
          <Stack direction='row' spacing={2} sx={{ alignItems: 'baseline' }}>
            {tags.map((tag) => (
              <Tag key={tag} label={tag} />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          top: 0,
          right: 0,
          justifyContent: 'flex-end'
        }}
      >
        {isAdmin && (
          <>
            <IconButton onClick={onEditProject}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={onDeleteProject}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Stack>
  )
}

export default ProjectCard
