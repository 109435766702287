import { Box, styled } from '@mui/material'

/**
 * A Box container with some default flexbox styling that comes up a lot.
 */
const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export default FlexBox
