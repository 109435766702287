import {
  type DevlogEntry,
  type Comparator,
  type DevlogTableColumn,
  type Order,
  type ElementEntry
} from './types'

const descendingComparator = (
  a: DevlogEntry,
  b: DevlogEntry,
  orderBy: DevlogTableColumn
): number => {
  if (orderBy === 'createdAt') {
    return b.createdAt - a.createdAt
  }
  if (orderBy === 'title') {
    return b.title.localeCompare(a.title)
  }
  if (orderBy === 'tags') {
    return b.tags.join(', ').localeCompare(a.tags.join(', '))
  }
  return 0
}

export const getComparator = (order: Order, orderBy: DevlogTableColumn): Comparator => {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy)
}

export const stableSort = (array: DevlogEntry[], comparator: Comparator): DevlogEntry[] => {
  if (array == null) return []

  return array.slice().sort(comparator)
}

export const stringifyDevlogContent = (content: any): string => {
  try {
    return JSON.stringify(content)
  } catch (error: any) {
    console.error(error)
    return ''
  }
}

export const headlineToSlug = (headline: string, index: number): string => {
  // Replace all spaces with dashes and all special characters with underscores
  // Prefix with 'id' to ensure it's a valid HTML id, can't start with a number
  return 'id-' + index + '-' + headline.toLowerCase().replace(/[\s]+/g, '-').replace(/[^a-z0-9-]/g, '_')
}

/**
 * Helper function to get the published date of a devlog entry.
 * If the devlog isn't published, it will return the createdAt date.
 * @param devlog the devlog entry to get the published date from
 * @returns a locale string of the published date
 */
export const getPublishedDate = (devlog: DevlogEntry): string => {
  const publishedOn = devlog.publishedOn === 0 ? devlog.createdAt : devlog.publishedOn

  return new Date(publishedOn).toLocaleDateString()
}

/**
 * Determines if an HTML element is a header tag (h1, h2, h3, h4, h5, h6)
 * @param element the HTML element to check
 * @returns true if the element is a header tag, false otherwise
 */
export const isHeaderTag = (element: Element): boolean => {
  const tagName = element.tagName.toLowerCase()
  const pattern = /h[1-6]/

  return pattern.test(tagName)
}

export const createMarkdownElementList = (elements: Element[]): ElementEntry[] => {
  const result: ElementEntry[] = []
  // Sort the elements by their position in the document
  const sortedElements = elements.sort((a, b) => {
    const aRect = a.getBoundingClientRect()
    const bRect = b.getBoundingClientRect()
    return aRect.top - bRect.top
  })
  let lastHeader: Element | null = null
  for (let i = 0; i < sortedElements.length; i++) {
    const element = sortedElements[i]
    const parent = isHeaderTag(element) ? null : lastHeader
    if (isHeaderTag(element)) {
      lastHeader = element
    }
    result.push({ element, parent, isVisible: false })
  }

  return result
}
