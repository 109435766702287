import React, { useState } from 'react'
import { Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { UPLOAD_IMAGE_PREVIEW_SIZE } from './config'
import { type ImageType } from './types'
import FlexBox from '../common/FlexBox'
import { useUploadDevlogImageMutation, useUploadProjectImageMutation } from './imagesSlice'
import { useDispatch } from 'react-redux'
import { openErrorSnackbar, openSuccessSnackbar } from '../navigation/navigationSlice'

interface ImageUploadProps {
  type: ImageType | null
  id?: string | null
  onClose?: () => void
}

function ImageUpload ({ type, id, onClose }: ImageUploadProps): JSX.Element {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [uploadProjectImage] = useUploadProjectImageMutation()
  const [uploadDevlogImage] = useUploadDevlogImageMutation()
  const [fileName, setFileName] = useState<string>('')
  const [fileSuffix, setFileSuffix] = useState<string>('')
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.gif']
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onDrop(acceptedFiles[0]).catch(console.error)
      }
    }
  })
  const uploadDisabled = imageFile == null || fileName === ''

  const onDrop = async (acceptedFile: File): Promise<void> => {
    console.log('Accepted files', acceptedFile)
    setImageFile(acceptedFile)
    const suffix = acceptedFile.name.split('.').pop()
    if (suffix != null) {
      setFileSuffix(suffix)
    } else {
      console.error(`Could not get file suffix for ${acceptedFile.name}`)
      setFileSuffix('')
    }
  }

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFileName(event.target.value)
  }

  const handleUpload = async (): Promise<void> => {
    if (imageFile == null || type == null) {
      console.error('Image file or type is null')
      return
    }
    try {
      const name = `${fileName}.${fileSuffix}`
      if (type === 'devlogs') {
        await uploadDevlogImage({ type, id, fileName: name, file: imageFile })
      } else {
        await uploadProjectImage({ type, fileName: name, file: imageFile })
      }
      dispatch(openSuccessSnackbar(`Image ${name} uploaded successfully`))
      if (onClose != null) onClose()
    } catch (error: any) {
      console.error(error)
      dispatch(openErrorSnackbar(error.message))
    }
  }

  const onUpload = (): void => {
    handleUpload().catch(console.error)
  }

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          border: `1px dashed ${theme.palette.grey[500]}`
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {imageFile == null
          ? (<FlexBox>
              <Typography p={4}>
                Drag and drop image here
              </Typography>
            </FlexBox>)
          : (<img
              src={URL.createObjectURL(imageFile)}
              width={UPLOAD_IMAGE_PREVIEW_SIZE}
            />)
        }
      </Box>
      <FlexBox>
        <TextField
          label='File Name'
          variant='outlined'
          fullWidth
          size='small'
          value={fileName}
          onChange={onTextChange}
        />
        {fileSuffix !== '' && (
          <Typography ml={1} color='text.secondary'>
            {`.${fileSuffix}`}
          </Typography>
        )}
      </FlexBox>
      <Button
        variant='contained'
        fullWidth
        disabled={uploadDisabled}
        onClick={onUpload}
      >
        Upload
      </Button>
    </Stack>
  )
}

export default ImageUpload
