import React, { type MouseEvent } from 'react'
import { TableCell, TableHead, TableSortLabel } from '@mui/material'
import { type Order, type DevlogHeadCell, type DevlogTableColumn } from './types'
import DevlogsTableRow from './DevlogsTableRow'

type Event<T> = MouseEvent<T>

const headCells: DevlogHeadCell[] = [
  { id: 'title', label: 'Title' },
  { id: 'createdAt', label: 'Date' },
  { id: 'tags', label: 'Tags' },
  { id: 'actions', label: '' }
]

interface DevlogsTableHeadProps {
  includeActions?: boolean
  order: Order
  orderBy: DevlogTableColumn
  onRequestSort: (event: Event<unknown>, property: DevlogTableColumn) => void
}

function DevlogsTableHead ({
  includeActions = false,
  onRequestSort,
  order,
  orderBy
}: DevlogsTableHeadProps): JSX.Element {
  const createSortHandler = (id: DevlogTableColumn) => (event: Event<HTMLAnchorElement>) => {
    onRequestSort(event, id)
  }
  // Filter out the actions column if it's not used
  const filteredCells = includeActions ? headCells : headCells.filter((headCell) => headCell.id !== 'actions')

  return (
    <TableHead>
      <DevlogsTableRow>
        {filteredCells.map((headCell) => (
          <TableCell key={headCell.id}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </DevlogsTableRow>
    </TableHead>
  )
}

export default DevlogsTableHead
