import React, { useState } from 'react'
import { Box, Fade, IconButton, ListItem, type SxProps, Typography, Tooltip } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'

interface ImageDirectoryProps {
  path: string
  name: string
  hoverInfo?: string
  onAdd?: (path: string) => void
  sx?: SxProps
}

function ImageDirectory ({ path, name, onAdd, hoverInfo, sx }: ImageDirectoryProps): JSX.Element {
  const [hover, setHover] = useState(false)

  const handleAdd = (): void => {
    if (onAdd != null) {
      onAdd(path)
    }
  }

  return (
    <Tooltip title={hoverInfo}>
      <ListItem
        onMouseEnter={() => { setHover(true) }}
        onMouseLeave={() => { setHover(false) }}
        sx={{ justifyContent: 'space-between', ...sx }}
        disablePadding
      >
        <Box display='flex'>
          <FolderOpenIcon />
          <Typography pl={1}>{name}</Typography>
        </Box>
        <Fade in={hover && onAdd != null}>
          <IconButton onClick={handleAdd}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Fade>
      </ListItem>
    </Tooltip>
  )
}

export default ImageDirectory
