import React, { type SyntheticEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  Box,
  Fab,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar
} from '@mui/material'
import RestoreIcon from '@mui/icons-material/Restore'
import SaveIcon from '@mui/icons-material/Save'
import { openErrorSnackbar, openSuccessSnackbar } from '../navigation/navigationSlice'
import { useUpdateDevlogMutation } from './devlogsSlice'
import { type Mode, type DevlogEntry } from './types'
import Devlog from './Devlog'
import TagSelect from '../form/TagSelect'
import PublishCheckbox from '../form/PublishCheckbox'
import FlexBox from '../common/FlexBox'

interface DevlogEditorProps {
  devlog?: DevlogEntry
}

function DevlogEditor ({ devlog }: DevlogEditorProps): JSX.Element {
  const [mode, setMode] = useState<Mode>('edit')
  const [updateDevlog] = useUpdateDevlogMutation()
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const {
    control,
    formState: { isDirty, isValid, defaultValues },
    getValues,
    setValue,
    handleSubmit,
    register,
    reset,
    watch
  } = useForm<DevlogEntry>({ defaultValues: devlog })
  const isEditing = mode === 'edit'
  const canSubmit = isValid && isDirty && !isSaving
  const canRevert = isDirty && !isSaving

  const handleModeChange = (event: SyntheticEvent, mode: Mode): void => {
    if (mode === null) return

    setMode(mode)
  }

  const handleCancel = (): void => {
    reset()
  }

  const onSubmit = (event: SyntheticEvent): void => {
    // Prevent the form from refreshing the page
    event.preventDefault()
    setIsSaving(true)
    handleSubmit(
      async (data) => {
        try {
          const result = await updateDevlog({
            newDevlog: data,
            currentDevlog: defaultValues as DevlogEntry
          })
          if ('error' in result) {
            throw new Error(result.error as string)
          }
          const { id } = result.data
          dispatch(openSuccessSnackbar(`Devlog ${id} updated successfully`))
          setIsSaving(false)
          // Reset the form to the new values
          const currentValues = getValues()
          reset(currentValues)
        } catch (error: any) {
          console.error(error)
          dispatch(openErrorSnackbar(error.message))
          setIsSaving(false)
        }
      },
      (errors) => {
        console.error(errors)
        dispatch(openErrorSnackbar('Error updating devlog'))
        setIsSaving(false)
      }
    )().catch(console.error)
  }

  return (
    <>
      {isEditing && (
        <form onSubmit={onSubmit}>
          <Toolbar
            sx={{
              '&.MuiToolbar-root': { padding: 0, minHeight: 'unset' },
              justifyContent: 'flex-end'
            }}>
            <Box>
              <Fab
                onClick={handleCancel}
                variant='extended'
                size='small'
                disabled={!canRevert}
              >
                <RestoreIcon />
              </Fab>
              <Fab
                type='submit'
                variant='extended'
                size='small'
                color='primary'
                disabled={!canSubmit}
                sx={{ ml: 1 }}
              >
                <SaveIcon />
              </Fab>
            </Box>
          </Toolbar>
          <Paper>
            <Stack spacing={2} p={2}>
              <Stack direction='row' spacing={2}>
                <TextField
                  label='Title'
                  variant='outlined'
                  fullWidth
                  {...register('title', { required: true })}
                />
                <TextField
                  label='Slug'
                  variant='outlined'
                  fullWidth
                  {...register('slug', { required: true })}
                />
                <Box>
                  <PublishCheckbox control={control} />
                </Box>
              </Stack>
              <TextField
                label='Blurb'
                variant='outlined'
                fullWidth
                {...register('blurb', { required: true })}
              />
              <FlexBox>
                <TagSelect control={control} setValue={setValue} watch={watch} />
              </FlexBox>
              <TextField
                label='Content'
                variant='outlined'
                fullWidth
                multiline
                rows={19}
                {...register('content', { required: true })}
              />
            </Stack>
          </Paper>
        </form>
      )}
      {!isEditing && <Devlog devlog={getValues()} />}
      <Toolbar
        sx={{
          mt: 1,
          '&.MuiToolbar-root': { padding: 0 },
          justifyContent: 'flex-end'
        }}>
        <ToggleButtonGroup
          value={mode}
          onChange={handleModeChange}
          exclusive
          >
          <ToggleButton value='edit' color='primary'>
            Edit
          </ToggleButton>
          <ToggleButton value='preview' color='primary'>
            Preview
          </ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
    </>
  )
}

export default DevlogEditor
