import React from 'react'
import { type ExtraProps } from 'react-markdown'
import Image from './Image'

/**
 * A custom component for react-markdown that won't wrap an image in a paragraph tag since the
 * custom Image component uses div elements.
 * Reference: https://github.com/remarkjs/react-markdown/issues/184
 */
function Paragraph (props: JSX.IntrinsicElements['p'] & ExtraProps): JSX.Element {
  const children = React.Children.toArray(props.children)
  const element = children[0] as JSX.Element
  const hasImage = element.type === Image

  return hasImage ? { ...element } : <p {...props} />
}

export default Paragraph
