import React, { useState } from 'react'
import { Fade, IconButton, ListItem, type SxProps, Typography, Tooltip, Box } from '@mui/material'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useFetchImageUrl } from './imagesSlice'
import { IMAGE_THUMBNAIL_SIZE } from './config'

interface ImageListItemProps {
  path: string
  name: string
  onDelete: (path: string) => void
  isUsed?: boolean
  sx?: SxProps
}

function ImageListItem ({ path, name, onDelete, isUsed = false, sx }: ImageListItemProps): JSX.Element {
  const [hover, setHover] = useState(false)
  const imageUrl = useFetchImageUrl(path)

  const handleDelete = (): void => {
    onDelete(path)
  }

  return (
    <Tooltip
      title={
        <Box sx={{ display: 'flex' }}>
          <img
            src={imageUrl ?? ''}
            alt={imageUrl ?? ''}
            width={IMAGE_THUMBNAIL_SIZE}
          />
        </Box>
      }
    >
      <ListItem
        onMouseEnter={() => { setHover(true) }}
        onMouseLeave={() => { setHover(false) }}
        sx={{ justifyContent: 'space-between', pl: 6, ...sx }}
        disablePadding
      >
        <Typography color={isUsed ? 'default' : 'GrayText'}>{name}</Typography>
        <Fade in={hover}>
          <IconButton onClick={handleDelete} size='small'>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Fade>
      </ListItem>
    </Tooltip>
  )
}

export default ImageListItem
