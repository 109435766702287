import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Container, type SxProps, Skeleton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useLoaderData, useNavigate } from 'react-router-dom'
import Devlog from './Devlog'
import { selectIsAuthenticated } from '../navigation/navigationSlice'
import DevlogEditor from './DevlogEditor'
import { type DevlogResponse } from '../firebase/types'

interface DevlogPageProps {
  sx?: SxProps
}

function DevlogPage ({ sx }: DevlogPageProps): JSX.Element {
  const { devlog, error } = useLoaderData() as DevlogResponse
  const navigate = useNavigate()
  const isAdmin = useSelector(selectIsAuthenticated)
  const hasSelectedDevlog = devlog != null
  if (error != null) {
    // TODO: Handle error
    console.error(error)
  }
  const handleBack = (): void => {
    navigate('/devlogs')
  }

  return (
    <Container sx={{ ...sx }}>
      <Button onClick={handleBack} startIcon={<ArrowBackIcon />}>
        Devlogs
      </Button>
      {!hasSelectedDevlog
        ? <Skeleton variant="rectangular" width="100%" height={300} />
        : (isAdmin
            ? <DevlogEditor devlog={devlog} />
            : <Devlog devlog={devlog} />)
      }
    </Container>
  )
}

export default DevlogPage
