import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Admin from '../admin/Admin'
import DevlogPage from '../devlogs/DevlogPage'
import { devlogByIdLoader, devlogBySlugLoader, projectByIdLoader } from './loaders'
import ErrorElement from '../common/ErrorElement'
import App from '../App'
import Tabs from '../navigation/Tabs'
import ProjectEditor from '../projects/ProjectEditor'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: '/',
        element: <Tabs />
      },
      {
        path: 'devlogs',
        element: <Tabs />
      },
      {
        path: 'admin',
        element: <Admin />
      },
      {
        path: '/projects/edit/:id',
        element: <ProjectEditor />,
        loader: projectByIdLoader,
        errorElement: <ErrorElement />
      },
      {
        path: '/devlogs/edit/:id',
        element: <DevlogPage />,
        loader: devlogByIdLoader,
        errorElement: <ErrorElement />
      },
      {
        path: '/devlogs/:slug',
        element: <DevlogPage />,
        loader: devlogBySlugLoader,
        errorElement: <ErrorElement />
      }
    ]
  }
])

export default router
