import { serverTimestamp } from 'firebase/firestore'

export const PROJECT_IMAGE_SIZE = 225

export const PROJECT_IMAGE_PREVIEW_SIZE = 220

export const PROJECT_THUMBNAIL_SIZE = 100

export const defaultProject = {
  title: 'Title of Project',
  blurb: 'A short blurb about the project',
  description: 'A longer description of the project',
  previewImage: '',
  tags: [],
  createdAt: serverTimestamp(),
  lastModified: serverTimestamp(),
  published: false,
  githubLink: '',
  siteLink: undefined
}
