/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  getDownloadURL,
  ref,
  uploadBytes,
  listAll,
  deleteObject,
  type FullMetadata
} from 'firebase/storage'
import firestoreApi from '../firebase/firestoreApi'
import { storage } from '../firebase/firebase'
import { type ImageUploadRequest, type ImageData, type ImageType, type PathDict } from './types'
import { Tag } from '../common/types'
import { useGetAllDevlogIds } from '../devlogs/devlogsSlice'
import { useDispatch } from 'react-redux'
import { openErrorSnackbar } from '../navigation/navigationSlice'
import { useEffect, useState } from 'react'

const fetchAllImages = async (type: ImageType): Promise<string[]> => {
  const storageRef = ref(storage, type)
  const listResults = await listAll(storageRef)
  const imagePaths = listResults.items.map((item) => item.fullPath)

  return imagePaths
}

// Async Api
// ------------------------------------------------------------
export const imagesApi = firestoreApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchProjectImagePaths: builder.query<string[], boolean>({
      async queryFn (publishedOnly) {
        try {
          const imagePaths = await fetchAllImages('projects')

          return { data: imagePaths }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      providesTags: [Tag.PROJECT_IMAGE_PATHS]
    }),
    fetchDevlogImagePaths: builder.query<PathDict, boolean>({
      async queryFn (publishedOnly) {
        try {
          const storageRef = ref(storage, 'devlogs')
          // Devlog images are stored in folders named after their id
          const devlogList = await listAll(storageRef)
          // Get the paths of each image in each devlog folder
          const imagePaths: PathDict = {}
          for (const { name, fullPath } of devlogList.prefixes) {
            // Map each devlog id folder to a list of image paths
            const parentRef = ref(storage, fullPath)
            const listResults = await listAll(parentRef)
            imagePaths[name] = listResults.items.map((item) => item.fullPath)
          }

          return { data: imagePaths }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      providesTags: [Tag.DEVLOG_IMAGE_PATHS]
    }),
    fetchProjectImageUrls: builder.query<ImageData[], boolean>({
      async queryFn (publishedOnly) {
        try {
          const imagePaths = await fetchAllImages('projects')
          // Get the download url for each previewImage path
          const imageUrls = await Promise.all(
            imagePaths.map(async (imagePath) => {
              const storageRef = ref(storage, imagePath)
              const url = await getDownloadURL(storageRef)

              return { path: imagePath, url }
            })
          )
          return { data: imageUrls }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      providesTags: [Tag.PROJECT_IMAGES]
    }),
    uploadProjectImage: builder.mutation<FullMetadata, ImageUploadRequest>({
      async queryFn ({ file, fileName, type }) {
        try {
          const filePath = `${type}/${fileName}`
          const storageRef = ref(storage, filePath)
          const result = await uploadBytes(storageRef, file)
          console.log('Uploaded file', { result })

          return { data: result.metadata }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.PROJECT_IMAGES, Tag.PROJECT_IMAGE_PATHS]
    }),
    uploadDevlogImage: builder.mutation<FullMetadata, ImageUploadRequest>({
      async queryFn ({ file, fileName, id, type }) {
        try {
          const filePath = `${type}/${id}/${fileName}`
          const storageRef = ref(storage, filePath)
          const result = await uploadBytes(storageRef, file)
          console.log('Uploaded file', { result })

          return { data: result.metadata }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.DEVLOG_IMAGES, Tag.DEVLOG_IMAGE_PATHS]
    }),
    deleteImage: builder.mutation<void, string>({
      async queryFn (imagePath) {
        try {
          const storageRef = ref(storage, imagePath)
          await deleteObject(storageRef)

          return { data: undefined }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [
        Tag.PROJECT_IMAGE_PATHS,
        Tag.DEVLOG_IMAGE_PATHS,
        Tag.PROJECT_IMAGES,
        Tag.DEVLOG_IMAGES
      ]
    }),
    deleteDevlogImages: builder.mutation<void, string>({
      async queryFn (devlogId) {
        try {
          const storageRef = ref(storage, `devlogs/${devlogId}`)
          await deleteObject(storageRef)

          return { data: undefined }
        } catch (error: any) {
          console.error(error)

          return { error: error.message }
        }
      },
      invalidatesTags: [Tag.DEVLOG_IMAGE_PATHS, Tag.DEVLOG_IMAGES]
    })
  })
})

// Custom Hooks
// ------------------------------------------------------------
export const useAllDevlogImagePaths = (publishedOnly: boolean): PathDict => {
  // TODO: Pass on the isLoading and isError values
  const { data = {} } = useFetchDevlogImagePathsQuery(false)
  const imagePaths = { ...data }
  // For any devlogs that don't have images, add an empty array
  const devlogIds = useGetAllDevlogIds(publishedOnly)
  devlogIds.forEach((id) => {
    if (imagePaths[id] === undefined) {
      imagePaths[id] = []
    }
  })

  return imagePaths
}

export const useFetchImageUrl = (imagePath: string): string | null => {
  const [url, setUrl] = useState<string | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const fetch = async (): Promise<void> => {
        const result = await getDownloadURL(ref(storage, imagePath))
        setUrl(result)
      }
      fetch()
        .catch((error: any) => {
          throw new Error(error.message)
        })
    } catch (error: any) {
      console.error(error)
      dispatch(openErrorSnackbar(error.message))
    }
  }, [imagePath])

  return url
}

export const {
  useFetchProjectImagePathsQuery,
  useFetchProjectImageUrlsQuery,
  useFetchDevlogImagePathsQuery,
  useUploadProjectImageMutation,
  useUploadDevlogImageMutation,
  useDeleteImageMutation,
  useDeleteDevlogImagesMutation
} = imagesApi
