import React from 'react'
import { List } from '@mui/material'
import ImageListItem from './ImageListItem'
import ImageDirectory from './ImageDirectory'
import { useFetchDevlogByIdQuery } from '../devlogs/devlogsSlice'

const isImageInDevlog = (imagePath: string, devlogContent: string): boolean => {
  return devlogContent.includes(`(${imagePath})`)
}

interface DevlogDirectoryProps {
  directory: string
  paths: string[]
  onDelete: (path: string) => void
  onAdd: (path: string) => void
}

function DevlogDirectory ({
  directory,
  paths,
  onDelete,
  onAdd
}: DevlogDirectoryProps): JSX.Element {
  const { data: devlog } = useFetchDevlogByIdQuery(directory)

  const handleAdd = (): void => {
    onAdd(directory)
  }

  return (
    <>
      <ImageDirectory
        path={directory}
        name={`${directory}/`}
        sx={{ pl: 6 }}
        onAdd={handleAdd}
        hoverInfo={devlog?.slug}
      />
      <List component='div' disablePadding>
        {paths.map((path) => {
          const parts = path.split('/')
          // Take just the last part of the path
          const name = parts.slice(-1)[0]
          const isUsed = devlog != null && isImageInDevlog(name, devlog.content)
          return (
            <ImageListItem
              key={path}
              name={name}
              path={path}
              onDelete={onDelete}
              isUsed={isUsed}
              sx={{ pl: 10 }}
            />
          )
        })}
      </List>
    </>
  )
}

export default DevlogDirectory
