import React from 'react'
import { Box, Skeleton, TableBody, TableCell } from '@mui/material'
import DevlogsTableRow from './DevlogsTableRow'

function PlaceholderTable (): JSX.Element {
  return (
    <TableBody>
      {Array.from(Array(5).keys()).map((index) => (
        <DevlogsTableRow key={index} hover>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Skeleton variant="rounded" width="15%" />
              <Skeleton variant="rounded" width="15%" />
              <Skeleton variant="rounded" width="15%" />
            </Box>
          </TableCell>
        </DevlogsTableRow>
      ))}
    </TableBody>
  )
}

export default PlaceholderTable
