import React from 'react'

/**
 * A function that behaves like String.prototype.replace() but accepts a replacer function that returns a Promise.
 * @param str the content to search and perform replacements on
 * @param pattern the regex pattern to match against
 * @param replacer an async function that returns a string to replace the matched pattern
 * @returns a Promise that resolves to the replaced string
 */
export const asyncReplace = async (str: string, pattern: RegExp, replacer: (match: string, ...args: any[]) => Promise<string>): Promise<string> => {
  const matches = str.matchAll(pattern)
  let replacedStr = str

  await Promise.all(
    Array.from(matches, async (match): Promise<void> => {
      // Access capturing groups starting from index 1
      const args = match.slice(1)
      const replacement = await replacer(match[0], ...args)

      replacedStr = replacedStr.replace(match[0], replacement)
    })
  )

  return replacedStr
}

/**
 * Recursively flatten React component children into a string.
 * This is useful for getting the text content of a React component.
 * @param text starting text to append to
 * @param child the React.Node to flatten
 * @returns a string of the flattened child text
 */
export const flattenChildren = (text: string, child: any): string => {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flattenChildren, text)
}

/**
 * Adds a smooth scrolling behavior to an anchor link when clicked.
 * @param anchorLink the element to add the behavior to
 */
export const addSmoothScroll = (anchorLink: Element): void => {
  anchorLink.addEventListener('click', (e) => {
    e.preventDefault()
    const element = e.target as HTMLAnchorElement
    const target = document.querySelector(element.hash)
    target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  })
}

/**
 * Parses a string into a JSON object inside a try/catch block.
 * If the string cannot be parsed, the original string is returned.
 * @param str the content string to parse
 * @returns a JSON object or the original string if it cannot be parsed
 */
export const safeJSONParse = (str: string): any => {
  try {
    return JSON.parse(str)
  } catch (e) {
    console.error('[safeJSONParse] Error while parsing', str, e)
    return str
  }
}

/**
 * Concatenates two arrays and removes duplicate elements.
 * @param arr1 the first array to merge
 * @param arr2 the second array to merge
 * @returns a new array with unique elements from both arrays
 */
export const mergeUnique = <T>(arr1: T[], arr2: T[]): T[] => {
  const set = new Set([...arr1, ...arr2])
  return Array.from(set)
}

/**
 * Filters out empty strings and duplicates from an array of strings.
 * @param arr the array of strings to filter
 * @returns a new array with unique strings
 */
export const filterUniqueStrings = (arr: string[]): string[] => {
  const set = new Set(arr)
  return Array.from(set).filter((item) => item != null && item !== '')
}

/**
 * Converts a File object to a base64 string.
 * @param file the File object to convert
 * @returns a Promise that resolves to a base64 string
 */
export const fileToBase64 = async (file: File): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (): void => { resolve(reader.result as string) }
    reader.onerror = (error): void => { reject(error) }
  })
}
