import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { Tag } from '../common/types'

const firestoreApi = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: [
    Tag.PROJECTS,
    Tag.DEVLOGS,
    Tag.PROJECT_IMAGES,
    Tag.DEVLOG_IMAGES,
    Tag.PROJECT_IMAGE_PATHS,
    Tag.DEVLOG_IMAGE_PATHS
  ],
  endpoints: () => ({})
})

export default firestoreApi
