import React from 'react'
import { useRouteError } from 'react-router-dom'
import { Container, Paper, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

/**
 * A fallback element to display when an error occurs while loading a route
 * The error is also logged to the console
 */
function ErrorElement (): JSX.Element {
  const error = useRouteError() as Error
  console.error(error)

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant='h3'>
        Well, this is embarrassing...
      </Typography>
      <Typography variant='h6'>
        It looks like an error occurred while trying to load this page
      </Typography>
      <Paper sx={{ mt: 2, p: 2 }}>
        <Grid2 container>
          <Grid2 xs={6} sm={2}>
            <Stack spacing={2}>
              <Typography color='GrayText'>Name</Typography>
              <Typography color='GrayText'>Message</Typography>
            </Stack>
          </Grid2>
          <Grid2 xs={6} sm={10}>
            <Stack spacing={2}>
              <Typography>{error?.name ?? 'Unknown'}</Typography>
              <Typography>{error?.message ?? 'Unknown'}</Typography>
            </Stack>
          </Grid2>
        </Grid2>
      </Paper>
    </Container>
  )
}

export default ErrorElement
