import React, { useState, type SyntheticEvent } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { Box, Button, Container, IconButton, Stack, TextField, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { useForm } from 'react-hook-form'
import { type ProjectResponse } from '../firebase/types'
import { type ProjectUpdateResponse, type ProjectEntry } from './types'
import { useUpdateProjectMutation } from './projectsSlice'
import { useFetchProjectImageUrlsQuery } from '../images/imagesSlice'
import PublishCheckbox from '../form/PublishCheckbox'
import RestoreIcon from '@mui/icons-material/Restore'
import FlexBox from '../common/FlexBox'
import TagSelect from '../form/TagSelect'
import ImageSelect from './ImageSelect'
import { useDispatch } from 'react-redux'
import { openErrorSnackbar, openSuccessSnackbar } from '../navigation/navigationSlice'

function ProjectEditor (): JSX.Element {
  const { project, error } = useLoaderData() as ProjectResponse
  const navigate = useNavigate()
  const [updateProject] = useUpdateProjectMutation()
  const [isSaving, setIsSaving] = useState(false)
  const { data: allImageOptions = [] } = useFetchProjectImageUrlsQuery(false)
  const dispatch = useDispatch()
  const {
    control,
    formState: { isDirty, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = useForm<ProjectEntry>({ defaultValues: project })
  if (error != null || project == null) {
    console.log('Error loading project', { error })
    return <Typography variant='h4'>Error loading project</Typography>
  }

  const createdAt = new Date(project.createdAt)
  const lastModified = new Date(project.lastModified)
  const canSubmit = isValid && isDirty && !isSaving
  const canRevert = isDirty && !isSaving

  const onCancel = (): void => {
    navigate('/')
  }

  const onRevert = (): void => {
    reset()
  }

  const onSubmit = (event: SyntheticEvent): void => {
    // Prevent the form from refreshing the page
    event.preventDefault()
    setIsSaving(true)
    handleSubmit(async (data) => {
      try {
        const result: ProjectUpdateResponse = await updateProject(data)
        if (result.error != null) {
          console.error(result.error)
          dispatch(openErrorSnackbar(result.error))
        } else {
          // Reset the form to the new values
          const currentValues = getValues()
          reset(currentValues)
          dispatch(openSuccessSnackbar('Project updated'))
        }
      } catch (error: any) {
        console.error(error)
        dispatch(openErrorSnackbar(error.message))
      }
    })()
      .catch(console.error)
      .finally(() => {
        setIsSaving(false)
      })
  }

  return (
    <Container>
      <Stack spacing={4}>
        <form onSubmit={onSubmit}>
          <FlexBox mb={3}>
            <Box>
              <Button onClick={onCancel}>
                ← Back
              </Button>
            </Box>
            <Typography variant='h4'>Edit Project</Typography>
            <Box sx={{ display: 'flex' }}>
              <IconButton onClick={onRevert} disabled={!canRevert}>
                <RestoreIcon />
              </IconButton>
              <IconButton type='submit' color='primary' disabled={!canSubmit}>
                <SaveIcon />
              </IconButton>
            </Box>
          </FlexBox>
          <Stack direction='row' spacing={2}>
            <Box>
              <ImageSelect control={control} imageOptions={allImageOptions} name='previewImage' />
            </Box>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack direction='row' spacing={2}>
                <TextField
                  fullWidth
                  label='Title'
                  variant='outlined'
                  size='small'
                  {...register('title')}
                />
                <PublishCheckbox control={control} />
              </Stack>
              <TextField
                fullWidth
                label='Blurb'
                variant='outlined'
                multiline
                size='small'
                rows={2}
                {...register('blurb')}
              />
              <TextField
                fullWidth
                label='Description'
                variant='outlined'
                multiline
                size='small'
                rows={6}
                sx={{
                  minWidth: 400
                }}
                {...register('description')}
              />
              <TextField
                fullWidth
                label='GitHub Link'
                variant='outlined'
                size='small'
                {...register('githubLink')}
              />
              <TextField
                fullWidth
                label='Site Link'
                variant='outlined'
                size='small'
                {...register('siteLink')}
              />
              <TagSelect control={control} setValue={setValue} watch={watch} sx={{ mb: 2 }} />
            </Stack>
          </Stack>
        </form>
        <FlexBox>
          <FlexBox>
            <Typography variant='body2' pr={1}>Id: </Typography>
            <Typography variant='body2'>{project.id}</Typography>
          </FlexBox>
          <FlexBox>
            <Typography variant='body2' pr={1}>Created At: </Typography>
            <Typography variant='body2'>
              {createdAt.toLocaleString()}
            </Typography>
          </FlexBox>
          <FlexBox>
            <Typography variant='body2' pr={1}>Last Modified: </Typography>
            <Typography variant='body2'>
              {lastModified.toLocaleString()}
            </Typography>
          </FlexBox>
        </FlexBox>
      </Stack>
    </Container>
  )
}

export default ProjectEditor
