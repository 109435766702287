import { type LoaderFunctionArgs } from 'react-router-dom'
import { type ProjectResponse, type DevlogResponse } from '../firebase/types'
import { fetchDevlogById, fetchDevlogBySlug, fetchProjectById } from '../firebase/firebase'

export const devlogByIdLoader = async ({ params }: LoaderFunctionArgs): Promise<DevlogResponse> => {
  if (params.id == null) throw new Error('[devlogByIdLoader] No id provided')

  return await fetchDevlogById(params.id)
}

export const devlogBySlugLoader = async ({ params }: LoaderFunctionArgs): Promise<DevlogResponse> => {
  if (params.slug == null) throw new Error('No slug provided')

  return await fetchDevlogBySlug(params.slug, true)
}

export const projectByIdLoader = async ({ params }: LoaderFunctionArgs): Promise<ProjectResponse> => {
  if (params.id == null) throw new Error('[projectByIdLoader] No id provided')

  return await fetchProjectById(params.id)
}
