import { configureStore } from '@reduxjs/toolkit'
import navigationReducer from '../navigation/navigationSlice'
import firestoreApi from '../firebase/firestoreApi'

const store = configureStore({
  reducer: {
    [firestoreApi.reducerPath]: firestoreApi.reducer,
    navigation: navigationReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(firestoreApi.middleware)
})

export default store
