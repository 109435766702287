import React, { useState } from 'react'
import { Box, Skeleton, useTheme } from '@mui/material'
import { type ExtraProps } from 'react-markdown'

const DEVLOG_DEFAULT_IMAGE_WIDTH = 400

const getWidthFromAlt = (content: string): number => {
  const pattern = /width\s*=\s*(\d+)/
  const match = content.match(pattern)
  if (match == null) return DEVLOG_DEFAULT_IMAGE_WIDTH

  const width = Number(match[1])
  if (isNaN(width)) return DEVLOG_DEFAULT_IMAGE_WIDTH

  return width
}

function CustomImage (props: JSX.IntrinsicElements['img'] & ExtraProps): JSX.Element {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const altContent = props.alt != null ? props.alt.split('|') : []
  let width = DEVLOG_DEFAULT_IMAGE_WIDTH
  if (altContent.length > 1) {
    width = getWidthFromAlt(altContent[1])
  }

  return (
    <Box
      sx={{
        width: 'fit-content',
        margin: 'auto',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden',
          lineHeight: 0
        }}
        >
        <img
          width={width}
          onLoad={() => { setIsLoading(false) }}
          style={{ opacity: isLoading ? 0 : 1 }}
          {...props}
        />
        <Skeleton
          variant='rectangular'
          width={width}
          height={width * 0.5}
          sx={{ display: isLoading ? 'block' : 'none' }}
        />
      </Box>
      <Box textAlign='center' fontSize='caption' color='text.secondary'>
        {altContent[0]}
      </Box>
    </Box>
  )
}

export default CustomImage
