import React from 'react'
import { Box, Tooltip } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { type Path, type Control, type FieldValues } from 'react-hook-form'
import Checkbox from './Checkbox'

interface PublishCheckboxProps<T extends FieldValues> {
  control: Control<T>
}

function PublishCheckbox<T extends FieldValues> ({ control }: PublishCheckboxProps<T>): JSX.Element {
  return (
    <Tooltip title='Publish'>
      <Box>
        <Checkbox
          icon={<StarOutlineIcon />}
          checkedIcon={<StarIcon />}
          name={'published' as Path<T>}
          control={control}
        />
      </Box>
    </Tooltip>
  )
}

export default PublishCheckbox
