import { createSlice } from '@reduxjs/toolkit'

interface NavigationState {
  isAuthenticated: boolean
  errorSnackbarOpen: boolean
  successSnackbarOpen: boolean
  errorSnackbarMessage: string
  successSnackbarMessage: string
}

const initialState: NavigationState = {
  isAuthenticated: false,
  errorSnackbarOpen: false,
  successSnackbarOpen: false,
  errorSnackbarMessage: '',
  successSnackbarMessage: ''
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setIsAuthenticated (state, action) {
      state.isAuthenticated = action.payload
    },
    openErrorSnackbar (state, action) {
      state.errorSnackbarOpen = true
      state.errorSnackbarMessage = action.payload
    },
    openSuccessSnackbar (state, action) {
      state.successSnackbarOpen = true
      state.successSnackbarMessage = action.payload
    },
    closeErrorSnackbar (state) {
      state.errorSnackbarOpen = false
      state.errorSnackbarMessage = ''
    },
    closeSuccessSnackbar (state) {
      state.successSnackbarOpen = false
      state.successSnackbarMessage = ''
    }
  }
})

// Actions
// ------------------------------------------------------------
export const {
  setIsAuthenticated,
  openErrorSnackbar,
  openSuccessSnackbar,
  closeErrorSnackbar,
  closeSuccessSnackbar
} = navigationSlice.actions

// Selectors
// ------------------------------------------------------------
export const selectIsAuthenticated =
  (state: { navigation: NavigationState }): boolean => state.navigation.isAuthenticated

export const selectErrorSnackbarOpen =
  (state: { navigation: NavigationState }): boolean => state.navigation.errorSnackbarOpen

export const selectSuccessSnackbarOpen =
  (state: { navigation: NavigationState }): boolean => state.navigation.successSnackbarOpen

export const selectErrorSnackbarMessage =
  (state: { navigation: NavigationState }): string => state.navigation.errorSnackbarMessage

export const selectSuccessSnackbarMessage =
  (state: { navigation: NavigationState }): string => state.navigation.successSnackbarMessage

export default navigationSlice.reducer
