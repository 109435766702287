import React from 'react'
import { Chip, type SxProps, useTheme } from '@mui/material'

interface TagProps {
  label: string
  sx?: SxProps
  variant?: 'outlined' | 'filled'
}

function Tag ({ label, sx, variant = 'filled' }: TagProps): JSX.Element {
  const theme = useTheme()

  return (
    <Chip
      label={label}
      variant={variant}
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.tag,
        borderColor: theme.palette.primary.main,
        ...sx
      }}
    />
  )
}

export default Tag
